<template>
  <div>
    <h2 class="gradient-text text-3xl">Naši lidé</h2>
    <p class="text-gray-400 pb-12">vedení spolku</p>

    <div class="w-full md:w-3/4 mx-auto grid grid-cols-2 md:grid-cols-5 p-4">
      <div>
        <img
          src="/assets/images/michaela.png"
          alt="Michaela Hadravová"
          class="p-3 mx-auto"
        />

        <p>
          Michaela Hadravová<br />
          <span class="text-gray-700">předsedkyně</span>
        </p>
      </div>

      <div>
        <img
            src="/assets/images/pavlina.png"
            alt="Pavlína Forétková"
            class="p-3 mx-auto"
        />

        <p>
          Pavlína Forétková<br />
          <span class="text-gray-700">místopředsedkyně</span>
        </p>
      </div>

      <div>
        <img
          src="/assets/images/radim.jpg"
          alt="Radim Hejduk"
          class="p-3 mx-auto"
        />

        <p>
          Radim Hejduk<br />
          <span class="text-gray-700">pokladník</span>
        </p>
      </div>

      <div>
        <img
          src="/assets/images/jindrich.png"
          alt="Jindřich Klega"
          class="p-3 mx-auto"
        />

        <p>
          Jindřich Klega<br />
          <span class="text-gray-700">člen Rady</span>
        </p>
      </div>
    </div>

    <h2 class="gradient-text text-3xl pt-12">Kontakty</h2>
    <p class="pt-6">
      Italská 833/32, Vinohrady, 120 00 Praha 2<br />
      <span class="text-gray-700">e-mail:</span> spolek (zavináč)
      idealiste.cz<br />
      <span class="text-gray-700">bank. ú.:</span>&nbsp;
      <a
        href="https://ib.fio.cz/ib/transparent?a=2400720604"
        target="_blank"
        rel="noreferrer noopener"
        class="underline"
        >2400720604 / 2010</a
      ><br />
      <span class="text-gray-700">IČ:</span> 03678369
    </p>

    <div class="w-1/5 mx-auto text-center pt-12 pb-12">
      <a
        href="https://facebook.com/idealiste.cz"
        target="_blank"
        class="transition diuration-400 text-red-700 hover:text-purple-500 p-3"
        ><i class="fab fa-facebook-f text-4xl"></i
      ></a>

      <a
        href="https://twitter.com/idealistecz"
        target="_blank"
        class="transition diuration-400 text-red-700 hover:text-purple-500 p-3"
        ><i class="fab fa-twitter text-4xl"></i
      ></a>

      <a
        href="https://instagram.com/idealistecz"
        target="_blank"
        class="transition diuration-400 text-red-700 hover:text-purple-500 p-3"
        ><i class="fab fa-instagram text-4xl"></i
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts"
};
</script>

<style scoped></style>

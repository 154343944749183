<template>
  <div class="fixed z-2 top-0 left-0 w-full grid grid-cols-2" id="navbar">
    <div class="text-left z-4 p-3">
      <img
        class="w-12 transition duration-500 hover:opacity-75"
        src="/assets/images/logo_pruhled.png"
        alt="logo"
      />
    </div>

    <div class="relative top-3 right-3 text-right z-4 p-3 text-white">
      <a
        class="pr-3 transition duration-500 hover:opacity-75"
        href="https://facebook.com/idealiste.cz"
        target="_blank"
        rel="noreferrer noopener"
      >
        <i class="fab fa-facebook-f"></i>
      </a>

      <a
        class="pr-3 transition duration-500 hover:opacity-75"
        href="https://twitter.com/idealistecz"
        target="_blank"
        rel="noreferrer noopener"
      >
        <i class="fab fa-twitter"></i>
      </a>

      <a
        class="transition duration-500 hover:opacity-75"
        href="https://instagram.com/idealistecz"
        target="_blank"
        rel="noreferrer noopener"
      >
        <i class="fab fa-instagram"></i>
      </a>

      <a
        href="https://forum.idealiste.cz/"
        target="_blank"
        rel="noopener noreferrer"
        class="text-white md:border md:p-3 ml-4 transition duration-500 hover:bg-white hover:text-black"
        >Zapoj se!</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar"
};
</script>

<style scoped></style>

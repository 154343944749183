<template>
  <div>
    <div class="w-full">
      <a
        href="/assets/documents/idea/idea-poznani.pdf"
        target="_blank"
        rel="noopener noreferrer"
        class="transition duration-300 hover:opacity-80"
      >
        <img
          src="/assets/images/idea/idea-poznani.png"
          alt="idea poznání"
          class="w-2/3 mx-auto"
        />
      </a>
    </div>

    <div class="mt-6 w-full grid grid-cols-3 items-center">
      <div>
        <a
          href="/assets/documents/idea/idea-demokracie.pdf"
          target="_blank"
          rel="noopener noreferrer"
          class="transition duration-300 hover:opacity-80"
        >
          <img
            src="/assets/images/idea/idea-demokracie.png"
            alt="idea demokracie"
            class="w-1/2 ml-auto"
          />
        </a>
      </div>

      <div>
        <a
          href="/assets/documents/idea/idea-mesto.pdf"
          target="_blank"
          rel="noopener noreferrer"
          class="transition duration-300 hover:opacity-80"
        >
          <img
            src="/assets/images/idea/idea-mesto.png"
            alt="idea města"
            class="w-1/2 mx-auto"
          />
        </a>
      </div>

      <div>
        <a
          href="/assets/documents/idea/idea-prace.pdf"
          target="_blank"
          rel="noopener noreferrer"
          class="transition duration-300 hover:opacity-80"
        >
          <img
            src="/assets/images/idea/idea-prace.png"
            alt="idea práce"
            class="w-1/2 mr-auto"
          />
        </a>
      </div>

      <div class="mt-4">
        <a
          href="/assets/documents/idea/idea-socdem.pdf"
          target="_blank"
          rel="noopener noreferrer"
          class="transition duration-300 hover:opacity-80"
        >
          <img
            src="/assets/images/idea/idea-socdem.png"
            alt="idea socdem"
            class="w-1/2 ml-auto"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Idea"
};
</script>

<style scoped></style>

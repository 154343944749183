<template>
  <div
    id="start-screen"
    class="spolek-gradient flex items-center justify-center h-screen grid grid-cols-1 md:grid-cols-2"
  >
    <div class="spolek-gradient h-full md:h-screen text-center">
      <div class="relative top-1/4 md:top-1/3" id="hnuti">
        <h2 class="text-xl font-bold text-white">
          Nezávislý spolek Idealisté.cz
        </h2>
        <p class="pt-3 text-white mx-auto w-3/4 md:w-1/3">
          Spolek vznikl v roce 2014 jako nezávislá organizace sdružující podobně
          smýšlející lidi se zájmem o politiku a svět kolem sebe.
        </p>
        <button
          class="mt-12 border-2 rounded-md p-3 text-xl text-white font-bold home-button"
          @click="redirect('Spolek', 'spolek', 'hnuti')"
        >
          <i class="fas fa-arrow-alt-circle-right"></i> Přejít
        </button>
      </div>
    </div>

    <div class="hnuti-gradient h-full md:h-screen text-center" id="spolek">
      <div class="relative top-1/4 md:top-1/3" id="spolekHomeContent">
        <h2 class="text-xl font-bold text-white">
          Politické hnutí Idealisté
        </h2>
        <p class="pt-3 text-white mx-auto w-3/4 md:w-1/3">
          Hnutí Idealisté je politické uskupení založené v roce 2020 některými
          členy a členkami spolku.
        </p>
        <button
          class="mt-12 border-2 rounded-md p-3 text-xl text-white font-bold home-button"
          @click="openHnuti()"
        >
          <i class="fas fa-arrow-alt-circle-right"></i> Přejít
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  methods: {
    redirect: function(routeName, enlargedItem, shrinkedItem) {
      let wrapper = document.getElementById("start-screen");
      let ei = document.getElementById(enlargedItem);
      let si = document.getElementById(shrinkedItem);
      let content = document.getElementById("spolekHomeContent");
      wrapper.classList.remove("grid");
      ei.classList.add("w-full");
      ei.classList.add("squish-right");
      si.classList.add("hidden");
      content.style.opacity = "0";

      setTimeout(() => {
        this.$router.push({ name: routeName });
      }, 460);
    },
    openHnuti: function() {
      window.open("https://idealistehnuti.cz", "_blank");
    }
  }
};
</script>

<template>
  <div class="spolek-gradient h-screen w-full text-center">
    <Navbar></Navbar>
    <HeroSign></HeroSign>
  </div>

  <About></About>

  <hr class="w-full md:w-1/3 mx-auto m-12" />

  <div
    class="w-full md:w-1/2 mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 pt-6"
  >
    <div>
      <Idea></Idea>
    </div>
    <div>
      <Activities></Activities>
    </div>
  </div>

  <hr class="w-full md:w-1/3 mx-auto m-12" />

  <div class="w-full md:w-1/2 mx-auto text-center">
    <Contacts></Contacts>
  </div>
</template>

<script>
import HeroSign from "@/views/components/HeroSign";
import Navbar from "@/views/components/Navbar";
import About from "@/views/components/About";
import Idea from "@/views/components/Idea";
import Activities from "@/views/components/Activities";
import Contacts from "@/views/components/Contacts";

export default {
  name: "Spolek",
  components: { HeroSign, Navbar, About, Idea, Activities, Contacts }
};
</script>

<style scoped></style>

<template>
  <div class="md:text-right pl-4 md:pr-12">
    <h3
      class="gradient-text text-3xl text-center md:text-right md:pr-4 pt-6 md:pt-0"
    >
      Co děláme
    </h3>

    <p class="pr-4 pt-3">
      Naše priority a nápady se snažíme dostat mezi širší veřejnost, včetně té
      odborné a také do politiky. Nejvíce času věnujeme organizaci vzdělávacích
      seminářů a vytváření programových materiálů, jejichž hlavní východiska
      prezentujeme. Mimo tuto základní náplň vydáváme i časopis IDEA, který si
      můžete přečíst i online, a je pro nás důležité budovat komunitu lidí a
      přátelský kolektiv při neformálních setkáních a akcích spolku.
    </p>

    <p class="pr-4 pt-3">
      Jsme otevření novým lidem a novým myšlenkám. Chcete-li se zapojit do
      činnosti našeho spolku, stačí se zaregistrovat. Získáte tím přístup k
      detailním informacím o činnosti spolku a můžete se okamžitě zapojit do
      diskuze prostřednictvím
      <a
        href="https://forum.idealiste.cz"
        target="_blank"
        rel="noreferrer noopener"
        class="transition duration-300 text-red-900 hover:text-purple-900"
        >našeho fóra</a
      >.
    </p>

    <h4 class="text-gray-600 pt-8 pr-4 text-2xl">Programové dokumenty</h4>

    <div class="pt-6">
      <a
        href="/assets/documents/manifestos/idealne-vzdelavat.pdf"
        target="_blank"
        rel="noopener noreferrer"
        class="transition duration-300 hover:opacity-80"
      >
        <img
          src="/assets/images/manifestos/idealne-vzdelavat.png"
          alt="ideálně vzdělávat"
          class="w-1/4 float-left md:float-right pr-4"
        />
      </a>
      <a
        href="/assets/documents/manifestos/idealne-pracovat.pdf"
        target="_blank"
        rel="noopener noreferrer"
        class="transition duration-300 hover:opacity-80"
      >
        <img
          src="/assets/images/manifestos/idealne-pracovat.png"
          alt="ideálně pracovat"
          class="w-1/4 float-left md:float-right pr-4"
        />
      </a>
    </div>

    <div>
      <a
        href="/assets/documents/manifestos/idealiste-exekuce.pdf"
        target="_blank"
        rel="noopener noreferrer"
        class="transition duration-300 hover:opacity-80"
      >
        <img
          src="/assets/images/manifestos/idealiste-exekuce.png"
          alt="exekuce"
          class="w-1/4 float-left md:float-right pt-4 pr-4"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Activities"
};
</script>

<style scoped></style>

<template>
  <div class="relative z-0 top-1/3">
    <transition name="slideup" mode="out-in" appear>
      <h1 class="text-white font-bold pt-10 text-4xl md:text-6xl lg:text-9xl">
        IDEALISTÉ.CZ
      </h1>
    </transition>

    <transition v-if="waited" name="slideup" appear>
      <h2 class="text-gray-100 text-lg md:text-lg lg:text-xl p-3">
        SVOBODA / SPRAVEDLNOST / SOLIDARITA
      </h2>
    </transition>
  </div>
</template>

<script>
export default {
  name: "HeroSign",
  data() {
    return {
      waited: false
    };
  },
  created() {
    setTimeout(() => {
      this.waited = true;
    }, 1000);
  }
};
</script>

<style scoped>
@keyframes slideup {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.slideup-enter-active,
.slideup-leave-active {
  animation: slideup 1s ease;
}

.slideup-enter-from,
.slideup-leave-to {
  opacity: 0;
}
</style>

import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Spolek from "../views/Spolek.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/spolek",
    name: "Spolek",
    component: Spolek
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;

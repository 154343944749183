<template>
  <div class="w-full md:w-1/2 mx-auto p-4 md:p-12">
    <transition name="fade" appear>
      <div>
        <h3 class="text-3xl gradient-text text-center md:text-left">
          Kdo jsme
        </h3>
        <p class="pt-6 pb-6">
          <i class="fas fa-caret-right"></i> Jsme nezávislá názorová platforma,
          ve které se sdružují lidé různých profesí i zájmů. Spojuje nás
          přesvědčení, že ve společnosti hodnotu člověka neurčuje to, kolik má
          na účtě, ale to, co umí a co ho baví. Prosazujeme ideu veřejných
          služeb, které jsou kvalitní a dostupné široké veřejnosti, obzvláště co
          se týče škol, nemocnic, hromadné dopravy nebo bydlení. Neděláme mezi
          lidmi rozdíly. Každý má mít zaručena stejná práva a stejné
          příležitosti nezávisle na pohlaví, věku, sexuální orientaci nebo na
          tom, kde se narodil.
        </p>
        <iframe
          width="100%"
          height="600"
          class="youtube"
          src="https://www.youtube.com/embed/GV49zMdgaKM?controls=0"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>

<style scoped></style>
